import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  allowedOnlyTo,
  getAllowedPostTypes,
} from '../../../../utils/permissions'
import { Dialog, DialogContent, DialogTrigger } from '../../../shadcn/dialog'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  onSelectItem: (open: string) => void
  allowedPostTypes: string[]
}

export default function EntryDialog({
  open,
  setOpen,
  onSelectItem,
  allowedPostTypes,
}: Props) {
  const currentUser = useSelector((state: any) => state.login.user)

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className=" max-w-[400px] px-14 py-14 max-h-screen overflow-y-auto overflow-x-visible mx-mobile:h-[100dvh]">
          <div>
            <h1 className="text-2xl font-bold">Choose Type</h1>
            <div className="h-[1px] w-[60px] bg-black mt-[8px]" />
            <div className="flex flex-col gap-2 mt-4">
              {allowedPostTypes.map((x) => {
                return (
                  <div
                    key={x}
                    className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                    onClick={() => {
                      // setPostDialogOpen(true)
                      onSelectItem(x)
                    }}
                  >
                    {x}
                  </div>
                )
              })}
            </div>

            <h1 className="text-2xl font-bold mt-14">Or</h1>
            <div className="h-[1px] w-[60px] bg-black mt-[8px]" />
            <div className="flex flex-col gap-2 mt-4">
              {allowedOnlyTo(currentUser, ['Professional+']) && (
                <div
                  className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                  onClick={() => {
                    onSelectItem('quote')
                  }}
                >
                  Add quote
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
