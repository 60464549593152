import authAxios from '../utils/authAxios'

export async function createTopic(values: any) {
  const res = await authAxios.post(`/topic`, {
    locationId: values.location,
    selectedUsers: values.parties,
    title: values.name,
    description: values.description,
    categoryId: values.category,
    moderators: values.moderators,
  })

  return res.data
}

export async function getOneTopic(id: string) {
  const res = await authAxios.post(`/v2/topic/getOneTopic`, {
    id: id,
  })

  return res.data
}

export async function getMyLocation() {
  const res = await authAxios.get(`/v2/user/getMyLocation`)

  return res.data
}

export async function getTopicCategories() {
  const res = await authAxios.get(`/v2/topic/getTopicCategories`)

  return res.data
}

export async function getTopicCategoriesWithTopics() {
  const res = await authAxios.get(`/v2/topic/getTopicCategoriesWithTopics`)

  return res.data
}

export async function getFollowingTopics() {
  const res = await authAxios.get(`/v2/topic/getFollowingTopics`)

  return res.data
}

export async function getTopicStatus(id: string) {
  const res = await authAxios.post(`/v2/topic/getTopicStatus`, {
    topicId: id,
  })

  return res.data
}

export async function followATopic(topicId: string) {
  const res = await authAxios.post(`/v2/topic/followATopic`, {
    topicId,
  })

  return res.data
}

export async function unfollowATopic(topicId: string) {
  const res = await authAxios.post(`/v2/topic/unfollowATopic`, {
    topicId,
  })

  return res.data
}

export async function getTopicForUser(userId: string | undefined) {
  const res = await authAxios.post(`/v2/topic/getTopicsForUser`, {
    userId,
  })

  return res.data
}

export async function getTopicForOrganization(orgId: string | undefined) {
  const res = await authAxios.post(`/v2/topic/getTopicsForOrganization`, {
    orgId,
  })

  return res.data
}
