import React from 'react'

type Props = {
  currentOrgType?: string
  onClick: () => void
}

export default function OrganizationFilterWheelButton({
  currentOrgType,
  onClick,
}: Props) {
  function getSelectedFill(org: string) {
    if (org.toLowerCase() === currentOrgType?.toLowerCase()) {
      return '#d5e7c4'
    } else {
      return '#303655'
    }
  }

  return (
    <button className="h-[104px] w-[104px] cursor-pointer" onClick={onClick}>
      <svg
        className="w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="72"
        fill="none"
        viewBox="0 0 72 72"
      >
        <g>
          <path
            stroke="#FBFBFD"
            d="M7.595 55.794l.295.406.405-.297 28-20.5 1.234-.903H1v.5c0 7.49 2.185 14.723 6.595 20.794z"
            fill={getSelectedFill('Government media')}
          ></path>
          <path
            fill={getSelectedFill('Private company/org')}
            stroke="#FBFBFD"
            d="M25.018 68.759l.476.154.154-.475 10.827-33.283.48-1.474-1.25.916-28 20.5-.402.294.292.403c4.398 6.057 10.283 10.642 17.423 12.965z"
          ></path>
          <path
            fill={getSelectedFill('NGO')}
            stroke="#FBFBFD"
            d="M47.155 68.975l.477-.154-.157-.477-11-33.5L36 33.397l-.475 1.447-11 33.5-.156.477.477.154c7.13 2.316 15.179 2.318 22.309 0z"
          ></path>
          <path
            fill={getSelectedFill('International Organization')}
            stroke="#FBFBFD"
            d="M64.904 55.794l.296-.406-.408-.294-28.5-20.5-1.246-.896.479 1.458 11 33.5.156.474.474-.154c7.14-2.32 13.35-7.126 17.75-13.182z"
          ></path>
          <path
            fill={getSelectedFill('Educational Organization')}
            stroke="#FBFBFD"
            d="M71.5 35v-.5H34.449l1.259.906 28.5 20.5.404.29.293-.402C69.314 49.723 71.5 42.489 71.5 35z"
          ></path>
          <path
            fill={getSelectedFill('Non-profit media')}
            stroke="#FBFBFD"
            d="M25.02 1.524l.473-.153.155.473 10.827 33 .482 1.468-1.25-.907-28-20.326-.407-.295.297-.405C11.994 8.373 17.88 3.827 25.019 1.524z"
          ></path>
          <path
            fill={getSelectedFill('For-profit media')}
            stroke="#FBFBFD"
            d="M7.195 14.699l.292-.386.395.278 28.406 20 1.29.909H.5V35c0-7.314 2.219-14.376 6.695-20.301z"
          ></path>
          <path
            fill={getSelectedFill('Involved Parties')}
            stroke="#FBFBFD"
            d="M47.15 2.177l.488.153-.165.485-11 32.346L36 36.553l-.473-1.392-11-32.346-.165-.485.488-.153c7.127-2.235 15.173-2.237 22.3 0z"
          ></path>
          <path
            fill={getSelectedFill('Government')}
            stroke="#FBFBFD"
            d="M65.4 14.506l.31.414-.424.296-29 20.194-1.248.869.489-1.44 11.192-33 .157-.463.467.147c7.26 2.284 13.579 7.016 18.057 12.983z"
          ></path>
          <path
            fill={getSelectedFill('Political Organization')}
            stroke="#FBFBFD"
            d="M71.5 35v.5H34.417l1.296-.91 28.5-20 .398-.278.29.39C69.314 20.626 71.5 27.687 71.5 35z"
          ></path>
          <circle
            cx="35.767"
            cy="35.001"
            r="13.333"
            fill="#FBFBFD"
            stroke="#303655"
          ></circle>
          <circle cx="36.25" cy="35.367" r="34.75" stroke="#303655"></circle>
        </g>
      </svg>
    </button>
  )
}
