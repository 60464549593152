import { User } from '../types/user'

export function getAllowedPostTypes(
  availablePostTypes: string[] = [],
  allowedPostTypes: string[] = []
) {
  let types = []
  for (const allowePostType of allowedPostTypes) {
    if (availablePostTypes.find((x) => x === allowePostType)) {
      types.push(allowePostType)
    }
  }

  return types
}

export function allowedOnlyTo(user?: User, roles?: string[]) {
  if (user && roles) {
    return !!roles?.find(
      (x) => x?.toLocaleLowerCase() === user?.roles?.name?.toLocaleLowerCase()
    )
  }
}

export function doesUserHasMediaOrg(user: User) {
  // console.log({ user })
  if (!user) return false

  const mediaOrgs = [
    'Non-profit media',
    'For-profit media',
    'Government media',
    // 'Private company/org',
  ]

  return !!user.organizationUsers.find(
    (x) => !!mediaOrgs.find((y) => y.toLowerCase() === x.type.toLowerCase())
  )

  // console.log('🚀 ~ isAllowedToCreateArticle ~ isAllowed:', isAllowed)
}
