import { useEffect, useState } from 'react'
import { getAllTopics } from '../../redux/topics/actions'
import { selectPostDetails, postEditRules } from '../../redux/posts/actions'
import { connect, useDispatch } from 'react-redux'
import { getMe } from '../../redux/login/actions'
import { useNavigate } from 'react-router-dom'
import { addPostsToRedux } from '../../redux/analytics/actions'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'
import AddSVG from '../../assets/icons/add.svg'
// import EntryDialog from '../../containers/PostScreen/EntryDialog'
import EntryDialog from '../../components-v2/EntryDialog/EntryDialog'
import { ChevronRight } from 'lucide-react'
import {
  getTrustFeedChanges,
  trustFeedContrasts,
} from '../../redux/trustFeed/action'
import Header from '../../components-v2/Header/Header'
import { isAuthenticated } from '../../utils/auth'
import SidebarContent from '../../components-v2/Header/Sidebar/SidebarContent'
import { followATopic } from '../../services/topic'
import TopicFollowButton from '../../components-v2/TopicFollowButton'

const HomeContainer = ({
  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  postEditRules,
  addPostsToRedux,
  getAllTopics,
  topics,
  user,
  getMe,
  getTrustFeedChanges,
  trustFeedChanges,
  trustFeedContrasts,
  isTrustFeedSeen,
  trustFeedContrastsT,
  permissions,
  analyticsPosts,
}: any) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  const callTrustFeed = (lastLogin: Date) => {
    const date = new Date(lastLogin)
    const today = new Date()
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()

    if (!isToday && lastLogin) {
      getTrustFeedChanges()
      trustFeedContrasts()
    }
    return
  }

  useEffect(() => {
    if (isAuthenticated()) {
      getMe()
    } else {
      // navigate(
      //   '/topic/324d5030-1169-44c4-a88a-97733ac3f6e1?popup=landingPopup&defaultOpen=1'
      // )

      navigate('/landing')
    }
  }, [])

  useEffect(() => {
    getAllTopics()
    user?.lastLogin !== null && callTrustFeed(user?.lastLogin)
  }, [user?.lastLogin])

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (!isTrustFeedSeen) {
      if (trustFeedContrastsT?.length > 0 || trustFeedChanges?.length > 0) {
        navigate('/trust')
      }
    }
  }, [trustFeedChanges, trustFeedContrasts])

  const postEditRulesFunction = (post: any) => {
    postEditRules(post?.id)
  }

  const dispatch = useDispatch()
  // console.log(topics)
  const navigate = useNavigate()

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'auto',
  //   })
  // }, [topics])

  return (
    <>
      <Header />
      <AnalyticsTrackerWrapper>
        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="min-h-44 overflow-hidden overflow-x-hidden mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <SidebarContent className="fixed" />
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0"
            style={{
              flex: '0 0 40%',
            }}
          >
            {topics?.length > 0 ? (
              (topics || []).map((x: any, i: number) => {
                return (
                  <div key={i} className="w-full">
                    <div className="flex justify-center">
                      <div className="w-[390px]">
                        <div
                          className="flex cursor-pointer justify-between rounded-t-sm bg-line px-[12px] py-[10px] transition-all hover:bg-[#bfbfbf]"
                          onClick={() => {
                            navigate(
                              `/topic/${x.id !== 'local' ? x.id : 'local'}`
                            )
                          }}
                        >
                          <div className="w-full">
                            <p className="flex items-center justify-between text-[14px] font-semibold leading-4">
                              {x.title}

                              <TopicFollowButton
                                cb={getAllTopics}
                                topicId={x.id}
                              />

                              {/* <span
                                className="underline font-semibold text-[#2443B2] text-xs hover:bg-border rounded px-[3px] -mr-[3px] transition-all"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  followATopic(x.id).then(() => {
                                    getAllTopics()
                                  })
                                }}
                              >
                                + Follow
                              </span> */}
                            </p>
                            <div className="flex items-center justify-between gap-4 pt-[3px]">
                              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                                {x.locationId}
                              </p>
                              <div className="mt-1 flex h-[12px] flex-shrink-0 items-end whitespace-nowrap text-[12px] font-semibold text-border underline underline-offset-4">
                                See all posts
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <PostCreationForTopic
                      parentTopicId={x.id}
                      trigger={

                      }
                      cb={() => {
                        getAllTopics()
                      }}
                    /> */}

                        <EntryDialog
                          trigger={
                            <div className="flex h-[28px] w-full max-w-[390px] cursor-pointer items-center gap-[6px] rounded-b-lg border border-line bg-[#F5F5F5] px-[12px] transition-all hover:bg-line/50 mx-mobile:w-full">
                              <img src={AddSVG} className="h-4 w-4" />
                              <p className="text-[11px] text-border">
                                Create content
                              </p>
                            </div>
                          }
                          disableTopic={permissions?.topicCreation}
                          articleAllow={permissions?.articleCreation}
                          quotesAllow={permissions?.quotesCreation}
                          parentTopicId={x.id}
                          cb={() => {
                            getAllTopics()
                          }}
                        />
                      </div>
                    </div>

                    {x?.posts?.length > 0 && (
                      <div className="mt-6 flex w-full flex-col gap-6 mx-mobile:px-5">
                        {x?.posts?.map((p: any, i: number) => {
                          return (
                            <PostWithContrast
                              analyticsPosts={analyticsPosts}
                              addPostsToRedux={addPostsToRedux}
                              userLocation={user?.userLocations?.address}
                              post={p}
                              key={i}
                              pageLoad={() => {
                                getAllTopics()
                              }}
                            />
                          )
                        })}
                      </div>
                    )}
                    {x?.posts?.length > 1 ? (
                      <p
                        className={
                          'mx-auto mb-[15px] mt-[20px] flex w-fit cursor-pointer items-center rounded-md p-1 text-center font-inter text-xs font-semibold leading-[14.52px] text-[#2443B2] transition-all hover:bg-line'
                        }
                        onClick={() => {
                          navigate('topic/' + x.id)
                        }}
                      >
                        Read more <ChevronRight size={16} />
                      </p>
                    ) : (
                      <p
                        className={
                          'mx-auto mb-[15px] mt-[20px] flex w-fit cursor-pointer items-center rounded-md p-1 text-center font-inter text-xs font-semibold leading-[14.52px] text-[#2443B2] transition-all hover:bg-line'
                        }
                      ></p>
                    )}

                    {x?.posts?.length < 1 && (
                      <div className="mt-2 text-center text-xs text-border">
                        No Posts
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <div className="mt-10 text-center text-xs text-border">
                No Posts
              </div>
            )}
          </div>

          {/* Right div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          ></div>
        </div>
      </AnalyticsTrackerWrapper>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userError,
    permissions: state?.login?.user?.roles?.permissions,

    topics: state.topics.topics,
    trustFeedChanges: state.trustFeed.trustFeedChanges,
    trustFeedContrastsT: state.trustFeed?.trustFeedContrasts,
    isTrustFeedSeen: state.trustFeed.isTrustFeedSeen,
    analyticsPosts: state.analytics.viewed_posts,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMe: () => {
      dispatch(getMe())
    },
    getAllTopics: () => {
      dispatch(getAllTopics())
    },
    selectPostDetails: (post: any) => {
      dispatch(selectPostDetails(post))
    },
    postEditRules: (postId: any) => {
      dispatch(postEditRules(postId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
    getTrustFeedChanges: () => {
      dispatch(getTrustFeedChanges())
    },
    trustFeedContrasts: () => {
      dispatch(trustFeedContrasts())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
