import React from 'react'
// import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeScreen from './containers/HomeScreen'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material'
import TopicScreen from './containers/TopicScreen'
import AppContainer from './containers/AppContainer'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#000',
      },
    },
  })

  // text commit 2

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppContainer />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
