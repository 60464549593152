import React, { useCallback, useEffect, useState } from 'react'

import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'

import ConversationTrail from './ConversationTrail'
import PostCard from '../../components-v2/Post/PostCard'
import { parsePostObjToProps } from '../../components-v2/Post/helpers'
import Tabs from '../../components-v2/Tabs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Post } from '../../types'
import { cn } from '../../utils/cn'
import { getATopic, getPostWithTrails } from '../../services/public'
import NoAuthHeader from '../../components-v2/Header/NoAuthHeader'
import NoAuthDialog from '../../components-v2/NoAuthDialog'
import ContrastsTab from './tabs-public/ContrastsTab'
import CommentsTab from './tabs-public/CommentsTab'
import ArticlesTab from './tabs-public/ArticlesTab'

type Props = {}

export default function PublicPostPage({}: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get('tab')

  const [isNoAuthDialogOpen, setIsNoAuthDialogOpen] = useState(false)

  const [post, setPost] = useState<Post | null>(null)
  const [trailPosts, setTrailPosts] = useState<Post[]>([])
  const [currentTab, setCurrentTab] = useState(
    tabParam ? tabParam : 'contrasts'
  )
  const [topic, setTopic] = useState<any>(null)
  const navigate = useNavigate()

  const params = useParams<{ postId: string; topicId: string }>()
  const { topicId, postId } = params

  const pageLoad = useCallback(() => {
    if (postId) {
      setCurrentTab(tabParam ? tabParam : 'contrasts')
      getPostWithTrails(postId).then(({ trailPosts, currentPost }) => {
        if (trailPosts) {
          setTrailPosts(trailPosts)
        } else {
          setTrailPosts([])
        }

        if (currentPost) {
          setPost(currentPost)
        }

        if (!tabParam && currentPost?.con?.length < 1) {
          setCurrentTab('comments')
        }
      })
    }

    if (topicId) {
      getATopic(topicId).then((data: any) => {
        setTopic(data)
      })
    }
  }, [postId, topicId, tabParam])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  useEffect(() => {
    if (trailPosts.length <= 0) {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      })
    }
  }, [trailPosts])

  function onNoAuthDialogOpen() {
    setIsNoAuthDialogOpen(true)
  }

  let tab = null
  switch (currentTab) {
    case 'contrasts':
      tab = (
        <ContrastsTab
          post={post}
          // contrastsAllow={permissions?.contrastsCreation}
          onNoAuthDialogOpen={onNoAuthDialogOpen}
        />
      )
      break

    case 'comments':
      tab = <CommentsTab post={post} onNoAuthDialogOpen={onNoAuthDialogOpen} />
      break

    case 'articles':
      tab = (
        <ArticlesTab
          post={post}
          topicId={topicId}
          onNoAuthDialogOpen={onNoAuthDialogOpen}
        />
      )
      break
  }

  const tabsToShow = [
    {
      value: 'comments',
      label: 'Comments',
    },
    {
      value: 'contrasts',
      label: 'Contrasts',
    },
  ]

  if (post?.articleId) {
    tabsToShow.push({
      value: 'articles',
      label: 'Articles',
    })
  }

  let popupUrl = ''
  switch (searchParams.get('popup')) {
    case 'landingPopup':
      popupUrl = 'https://niooze.carrd.co/'
      break

    default:
      popupUrl = 'https://niooze-signup.carrd.co/'
      break
  }

  return (
    <>
      <NoAuthHeader noAuthClick={onNoAuthDialogOpen} />
      <div className="fixed top-10 z-10 flex h-11 w-full items-center justify-center bg-line px-5 text-sm font-semibold">
        <div className="flex w-[364px] items-center justify-between">
          <img
            src={ArrowLeftSVG}
            className="h-6 w-6 cursor-pointer rounded-lg transition-all hover:bg-border"
            onClick={() => {
              navigate(-1)
            }}
          />
          <h1
            className="cursor-pointer mx-mobile:text-xs"
            onClick={() => {
              navigate(`/topic/${topic?.id}`)
            }}
          >
            {topic?.title}
          </h1>
          <div className="w-6" />
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
        {/* Left div */}
        <div
          className="min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        ></div>

        {/* Middle div */}
        <div
          className="flex min-h-screen flex-col items-center border-r border-line pt-[60px] mx-desktop:border-r-0 mx-mobile:px-5"
          style={{
            flex: '0 0 40%',
          }}
        >
          {/* trail line */}
          {trailPosts.length > 0 && (
            <ConversationTrail
              trailPosts={trailPosts}
              cb={pageLoad}
              onNoAuthCallback={onNoAuthDialogOpen}
            />
          )}

          <div
            className={cn(
              'flex min-h-[calc(100vh-109px)] w-full flex-col items-center',
              {
                // 'mt-5': trailPosts.length <= 0,
              }
            )}
            id="trail-start"
          >
            {post && (
              <PostCard
                // addPostsToRedux={addPostsToRedux}
                postTypeForAnalytics={'home'}
                userLocation={null}
                {...parsePostObjToProps(post)}
                urlsCollapsed={false}
                onClickVote={onNoAuthDialogOpen}
                onClickAlarm={onNoAuthDialogOpen}
                onNoAuthCallback={onNoAuthDialogOpen}
                cb={pageLoad}
              />
            )}

            {/* Tabs */}
            <Tabs
              className="mb-6 mt-6"
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
              tabs={tabsToShow}
            />

            {tab}

            {/* {posts.map((x, i) => {
            return <PostWithContrast hasContrast={x.hasCon} />
          })} */}
          </div>
        </div>

        {/* Right div */}
        <div
          className="relative min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        ></div>
      </div>
      <NoAuthDialog
        setOpen={setIsNoAuthDialogOpen}
        open={isNoAuthDialogOpen}
        url={popupUrl}
        defaultOpen={Number(searchParams.get('defaultOpen')) === 1}
      />
    </>
  )
}
