import { Post } from '../types'
import authAxios from '../utils/authAxios'

export async function getOnePost(id: string) {
  const res = await authAxios.post('/post/getOnePost', {
    id: id,
  })

  return res.data as Post
}

export async function getPostWithTrails(postId: string) {
  const res = await authAxios.post('/v2/post/getPostWithTrail', {
    postId: postId,
  })

  return res.data as any
}

export async function createAQuote(data: any) {
  const res = await authAxios.post(`/v2/post/createAQuote`, data)

  return res.data
}

export async function updateAQuote(data: any) {
  const res = await authAxios.post(`/v2/post/updateAQuote`, data)

  return res.data
}

export async function getPostsOfATopic(topicId: string) {
  const res = await authAxios.post('/v2/post/getPostsOfATopic', {
    topicId: topicId,
  })

  return res.data as any
}

export async function getPostsOfALocalTopic() {
  const res = await authAxios.post('/v2/post/getPostsOfALocalTopic')

  return res.data as any
}

export async function getAllPostsForUser(userId: string | undefined) {
  const res = await authAxios.get(`/post/user/${userId}`)

  return res.data as any
}
