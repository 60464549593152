import { XIcon } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'
import FilterWheelEntry from '../../components-v2/FilterWheel3/FilterWheelEntry'
import {
  orderFilterOptions,
  timeFilterOptions,
} from '../../components-v2/FilterWheel3/helpers'
import Tabs from '../../components-v2/Tabs'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  deleteArticlesForTopic,
  getTopicArticles,
} from '../../redux/article/actions'
import {
  alarmToggleTopic,
  deletePost,
  getAllPosts,
  getAllPostsForTopics,
  voteToggleTopic,
} from '../../redux/posts/actions'
import {
  deleteTopic,
  getTopic,
  setClickedTopic,
} from '../../redux/topics/actions'
import { getAllArticlesOfATopic } from '../../services/article'
import { getPostsOfALocalTopic, getPostsOfATopic } from '../../services/post'
import DescriptionCard from '../ArticleScreen/DescriptionCard'
import {
  filterPosts,
  getDisabledStates,
  getInvolvedDisableState,
  getOrderFilteredPosts,
  getTimeFilteredPosts,
} from './helpers'
import './index.css'
import ArticlesTab from './tabs/ArticlesTab'
import PostsTab from './tabs/PostsTab'
import { isAuthenticated } from '../../utils/auth'
import Header from '../../components-v2/Header/Header'
import { getMe } from '../../redux/login/actions'
import { useTopicStore } from './topic.store'

const TopicScreen = ({
  getTopic,
  topic,
  setClickedTopic,

  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setContrastsModalVisible,
  topicPosts,
  getAllPostsForTopics,
  getTopicArticles,
  deletePost,
  deleteTopic,

  topicArticles = [],
  voteToggleTopic,
  deleteArticlesForTopic,
  setParamsForArticleModal,
  pageArticle,
  setPageArticle,
  addPostsToRedux,
  alarmToggleTopic,
  permissions,
  getMe,
  analyticsPosts,
}: any) => {
  // console.log('🚀 ~ topic:', topic)
  const [currentTab, setCurrentTab] = useState('posts')
  const params = useParams()
  const { topicId } = params

  const [loadingPosts, setLoadingPosts] = useState(false)
  // const [posts, setPosts] = useState<any[]>([])
  const posts = useTopicStore((state: any) => state.posts)
  const setPosts = useTopicStore((state: any) => state.setPosts)
  // console.log('🚀 ~ posts:', posts)
  // console.log(
  //   '🚀 ~ posts:',
  //   posts.filter((x) => !!x.organization)
  // )

  const [loadingArticles, setLoadingArticles] = useState(false)
  // const [articles, setArticles] = useState<any[]>([])
  const articles = useTopicStore((state: any) => state.articles)
  const setArticles = useTopicStore((state: any) => state.setArticles)

  const [filterPostType, setFilterPostType] = useState('all')
  const [filterOrganization, setFilterOrganization] = useState('all')

  const defaultFilterTime = 'all'
  const [filterTime, setFilterTime] = useState(defaultFilterTime)
  const defaultFilterOrder = 'new-to-old'
  const [filterOrder, setFilterOrder] = useState(defaultFilterOrder)

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)

  const isAuthed = isAuthenticated()

  const loadPosts = useCallback(() => {
    if (topicId) {
      setLoadingPosts(true)
      if (topicId === 'local') {
        //if the topic is local
        getPostsOfALocalTopic()
          .then((data) => {
            setPosts(data)
          })
          .finally(() => {
            setLoadingPosts(false)
          })
      } else {
        //if it is not a local topic
        getPostsOfATopic(topicId)
          .then((data) => {
            setPosts(data)
          })
          .finally(() => {
            setLoadingPosts(false)
          })
      }
    }
  }, [topicId])

  const loadArticles = useCallback(() => {
    if (topicId) {
      setLoadingArticles(true)
      getAllArticlesOfATopic(topicId)
        .then((data) => {
          setArticles(data)
        })
        .finally(() => {
          setLoadingArticles(false)
        })
    }
  }, [topicId])

  useEffect(() => {
    getTopic(topicId)
  }, [topicId])

  useEffect(() => {
    if (currentTab === 'posts') {
      loadPosts()
    }

    if (currentTab === 'articles') {
      loadArticles()
    }
  }, [currentTab, loadPosts, loadArticles])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'auto',
  //   })
  // }, [posts])

  // TODO: memoize this for performance
  const disabledItems = getDisabledStates(posts, topic?.associatedUsers)
  // const isAllowed = getInvolvedDisableState(posts, topic?.associatedUsers)
  // console.log('🚀 ~ isAllowed:', isAllowed)
  // console.log('🚀 ~ disabledItems:', disabledItems)

  // console.log({ topic })

  let tab = null
  switch (currentTab) {
    case 'posts':
      const filteredLayer1 = filterPosts(
        posts,
        filterPostType,
        filterOrganization,
        topic?.associatedUsers
      )
      const filteredLayer2 = getTimeFilteredPosts(filteredLayer1, filterTime)
      const filteredLayer3 = getOrderFilteredPosts(filteredLayer2, filterOrder)

      tab = (
        <PostsTab
          posts={filteredLayer3}
          cb={loadPosts}
          isLoading={loadingPosts && posts.length <= 0}
          analyticsPosts={analyticsPosts}
          addPostsToRedux={addPostsToRedux}
        />
      )
      break

    case 'articles':
      tab = (
        <ArticlesTab
          articles={articles}
          cb={loadArticles}
          isLoading={loadingArticles && articles.length <= 0}
        />
      )
      break
  }

  useEffect(() => {
    getMe()
  }, [getMe])

  const navigate = useNavigate()

  return (
    <>
      <Header />
      <div className={'mb-8'}>
        <div className="h-[38px]">
          <div className="fixed z-30 h-[38px] w-full justify-between border-b bg-white">
            <div className="mx-auto flex max-w-[390px] items-center">
              <img
                src={ArrowLeftSVG}
                className="h-6 w-6 cursor-pointer rounded-lg transition-all hover:bg-line"
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Tabs
                className="items-center py-2"
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                tabs={[
                  {
                    value: 'posts',
                    label: 'Posts',
                  },
                  {
                    value: 'articles',
                    label: 'Articles',
                  },
                ]}
              />

              <div className="h-6 w-6" />
            </div>
          </div>
        </div>

        {topic && (
          <div className="mx-auto hidden max-w-[390px] mx-desktop:block">
            <DescriptionCard
              permissions={permissions}
              title={topic.title}
              description={topic?.description}
              location={topic?.locationId}
              topicId={topic.id}
              cb={() => {
                loadPosts()
                // loadArticles()
              }}
            />
          </div>
        )}

        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            {topic && (
              <div className="fixed w-[30%] max-w-[600px]">
                <DescriptionCard
                  permissions={permissions}
                  title={topic.title}
                  description={topic?.description}
                  location={topic?.locationId}
                  topicId={topic.id}
                  cb={() => {
                    loadPosts()
                    // loadArticles()
                  }}
                />
                {/* <FilterEntry
                currentPostType={filterPostType}
                setPostType={setFilterPostType}
              /> */}

                {/* <PostTypeWheel /> */}
                {currentTab === 'posts' && (
                  <FilterWheelEntry
                    className="mt-10"
                    currentPostType={filterPostType}
                    setPostType={(type: string) => {
                      setFilterOrganization('all')
                      setFilterPostType(type)
                    }}
                    currentOrgType={filterOrganization}
                    setOrgType={(org: string) => {
                      setFilterPostType('all')
                      setFilterOrganization(org)
                    }}
                    setFilterOrder={setFilterOrder}
                    filterOrder={filterOrder}
                    setFilterTime={setFilterTime}
                    filterTime={filterTime}
                    postTypeWheelOpen={postTypeWheelOpen}
                    setPostTypeWheelOpen={setPostTypeWheelOpen}
                    orgWheelOpen={orgWheelOpen}
                    setOrgWheelOpen={setOrgWheelOpen}
                    disabledPostTypes={disabledItems.postTypes}
                    disabledOrgTypes={disabledItems.orgTypes}
                  />
                )}
              </div>
            )}
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 mx-mobile:px-5"
            style={{
              flex: '0 0 40%',
            }}
          >
            {/* Filters */}
            {currentTab === 'posts' && (
              <div className="mb-4 mt-5 flex flex-wrap items-center gap-1 mobile:px-5">
                {filterPostType !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    Filtering {filterPostType}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterPostType('all')
                      }}
                    />
                  </div>
                )}

                {filterOrganization !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setOrgWheelOpen(true)
                    }}
                  >
                    Filtering {filterOrganization}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrganization('all')
                      }}
                    />
                  </div>
                )}

                {filterTime && filterTime !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      timeFilterOptions.find((x) => x.value === filterTime)
                        ?.label
                    }
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterTime(defaultFilterTime)
                      }}
                    />
                  </div>
                )}

                {filterOrder && filterOrder !== 'new-to-old' && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      orderFilterOptions.find((x) => x.value === filterOrder)
                        ?.label
                    }

                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrder(defaultFilterOrder)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {tab}
          </div>
          {/* Right div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          ></div>
        </div>

        {currentTab === 'posts' && (
          <div
            className="h- fixed bottom-3 right-3 z-50 rounded-full bg-white desktop:hidden"
            style={{
              // '-webkit-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              // '-moz-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              boxShadow: 'rgb(255, 255, 255) 0px 0px 15px 8px',
            }}
          >
            <FilterWheelEntry
              currentPostType={filterPostType}
              setPostType={(type: string) => {
                setFilterOrganization('all')
                setFilterPostType(type)
              }}
              currentOrgType={filterOrganization}
              setOrgType={(org: string) => {
                setFilterPostType('all')
                setFilterOrganization(org)
              }}
              setFilterOrder={setFilterOrder}
              filterOrder={filterOrder}
              setFilterTime={setFilterTime}
              filterTime={filterTime}
              postTypeWheelOpen={postTypeWheelOpen}
              setPostTypeWheelOpen={setPostTypeWheelOpen}
              orgWheelOpen={orgWheelOpen}
              setOrgWheelOpen={setOrgWheelOpen}
              disabledPostTypes={disabledItems.postTypes}
              disabledOrgTypes={disabledItems.orgTypes}
            />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,
    permissions: state?.login?.user?.roles,

    topic: state.topics.topic,

    topicArticles: state.articles.topicArticles,
    topicsID: state.topics.topicsID,
    analyticsPosts: state.analytics.viewed_posts,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },
    getMe: () => {
      dispatch(getMe())
    },
    getTopicArticles: (topicId: string) => {
      dispatch(getTopicArticles(topicId))
    },
    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    deleteTopic: (topic: any) => {
      dispatch(deleteTopic(topic))
    },
    voteToggleTopic: (postId: any, topicId: any) => {
      dispatch(voteToggleTopic(postId, topicId))
    },
    alarmToggleTopic: (postId: any, topicId: any) => {
      dispatch(alarmToggleTopic(postId, topicId))
    },
    deleteArticlesForTopic: (artcileId: any, topicId: any) => {
      dispatch(deleteArticlesForTopic(artcileId, topicId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicScreen)
