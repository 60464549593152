import { create } from 'zustand'

export const useTopicStore = create((set) => ({
  posts: [],
  setPosts: (posts: any[]) => set({ posts: posts }),

  topic: null,
  setTopic: (topic: any) => set({ topic: topic }),

  articles: [],
  setArticles: (articles: any[]) => set({ articles: articles }),
}))
