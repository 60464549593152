import { XIcon } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'
import FilterWheelEntry from '../../components-v2/FilterWheel3/FilterWheelEntry'
import {
  orderFilterOptions,
  timeFilterOptions,
} from '../../components-v2/FilterWheel3/helpers'
import Tabs from '../../components-v2/Tabs'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  deleteArticlesForTopic,
  getTopicArticles,
} from '../../redux/article/actions'
import {
  alarmToggleTopic,
  deletePost,
  getAllPosts,
  getAllPostsForTopics,
  voteToggleTopic,
} from '../../redux/posts/actions'

import {
  deleteTopic,
  getTopic,
  setClickedTopic,
} from '../../redux/topics/actions'
import {
  getAllArticlesOfATopic,
  getArticleForUser,
} from '../../services/article'
import { getPostsOfALocalTopic, getPostsOfATopic } from '../../services/post'
import DescriptionCard from '../ArticleScreen/DescriptionCard'
import {
  filterPosts,
  getDisabledStates,
  getInvolvedDisableState,
  getOrderFilteredPosts,
  getTimeFilteredPosts,
} from './helpers'

import ArticlesTab from './tabs/ArticlesTab'
import PostsTab from './tabs/PostsTab'
import { isAuthenticated } from '../../utils/auth'
import Header from '../../components-v2/Header/Header'
import { getMe } from '../../redux/login/actions'
import { useUserStore } from './user.store'
import { getAllPostsForUser } from '../../services/post'
import { getUserDetails } from '../../services/user'
import { cn } from '../../utils/cn'
import TopicFollowButton from '../../components-v2/TopicFollowButton'
import DescriptionCardForScreens from './DescriptionCardForScreens'
import { getTopicForUser } from '../../services/topic'
import UserScreenTopics from '../../components/UserScreenTopics'
import whiteLens from '../../assets/icons/lens.svg'
import { followUsers } from '../../components-v2/Post/services'

const UserScreen = ({
  getTopic,
  topic,
  setClickedTopic,

  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setContrastsModalVisible,
  topicPosts,
  getAllPostsForTopics,
  getTopicArticles,
  deletePost,
  deleteTopic,

  topicArticles = [],
  voteToggleTopic,
  deleteArticlesForTopic,
  setParamsForArticleModal,
  pageArticle,
  setPageArticle,
  addPostsToRedux,
  alarmToggleTopic,
  permissions,
  getMe,
  analyticsPosts,
  loginDetails,
}: any) => {
  const [currentTab, setCurrentTab] = useState('posts')
  const params = useParams()
  const { userId } = params

  const [loadingPosts, setLoadingPosts] = useState(false)
  // const [posts, setPosts] = useState<any[]>([])
  const posts = useUserStore((state: any) => state.posts)
  const setPosts = useUserStore((state: any) => state.setPosts)
  const [allPosts, setAllPosts] = useState(
    useUserStore((state: any) => state.posts)
  )
  // console.log(
  //   '🚀 ~ posts:',
  //   posts.filter((x) => !!x.organization)
  // )

  const [loadingArticles, setLoadingArticles] = useState(false)
  // const [articles, setArticles] = useState<any[]>([])
  const articles = useUserStore((state: any) => state.articles)
  const setArticles = useUserStore((state: any) => state.setArticles)

  const userDetails = useUserStore((state: any) => state.userDetails)
  const setUserDetails = useUserStore((state: any) => state.setUserDetails)

  const topics = useUserStore((state: any) => state.topics)
  const setTopics = useUserStore((state: any) => state.setTopics)

  const [filterPostType, setFilterPostType] = useState('all')
  const [filterOrganization, setFilterOrganization] = useState('all')

  const defaultFilterTime = 'all'
  const [filterTime, setFilterTime] = useState(defaultFilterTime)
  const defaultFilterOrder = 'new-to-old'
  const [filterOrder, setFilterOrder] = useState(defaultFilterOrder)

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)
  const [userName, setUserName] = useState('')

  const isAuthed = isAuthenticated()

  const loadPosts = useCallback(() => {
    if (userId) {
      setLoadingPosts(true)
      if (userId) {
        getAllPostsForUser(userId)
          .then((data: any) => {
            setPosts(data)
          })
          .finally(() => {
            setLoadingPosts(false)
          })

        getUserDetails(userId)
          .then((data) => {
            setUserDetails(data)
          })
          .finally(() => {})
      }
    }
  }, [userId])

  const loadArticles = useCallback(() => {
    if (userId) {
      getArticleForUser(userId)
        .then((data) => {
          setArticles(data)
        })
        .finally(() => {
          setLoadingArticles(false)
        })
    }
  }, [userId])

  useEffect(() => {
    getUserDetails(userId)
      .then((data) => {
        setUserDetails(data)
      })
      .finally(() => {})

    getTopicForUser(userId)
      .then((data) => {
        setTopics(data)
      })
      .finally(() => {})
  }, [userId])

  useEffect(() => {
    if (currentTab === 'posts') {
      loadPosts()
    }

    if (currentTab === 'articles') {
      loadArticles()
    }
  }, [currentTab, loadPosts, loadArticles])

  // TODO: memoize this for performance
  const disabledItems = getDisabledStates(posts, topic?.associatedUsers)

  let tab = null
  switch (currentTab) {
    case 'posts':
      const filteredLayer1 = filterPosts(
        posts,
        filterPostType,
        filterOrganization,
        topic?.associatedUsers
      )
      const filteredLayer2 = getTimeFilteredPosts(filteredLayer1, filterTime)
      const filteredLayer3 = getOrderFilteredPosts(filteredLayer2, filterOrder)

      tab = (
        <PostsTab
          posts={filteredLayer3}
          cb={loadPosts}
          isLoading={loadingPosts && posts.length <= 0}
          analyticsPosts={analyticsPosts}
          addPostsToRedux={addPostsToRedux}
        />
      )
      break

    case 'articles':
      tab = (
        <ArticlesTab
          articles={articles}
          cb={loadArticles}
          isLoading={loadingArticles && articles.length <= 0}
        />
      )
      break
  }

  useEffect(() => {
    getMe()
  }, [getMe])

  const [previousTopicId, setPreviousTopicId] = useState('')
  const filterPostsBasedOnTopic = (topicId: string) => {
    getAllPostsForUser(userId)
      .then((data: any) => {
        if (topicId === previousTopicId) {
          setPosts(data)
          setPreviousTopicId('')
          return
        }
        const filterPosts = data.filter(
          (post: any) => post?.topicId === topicId
        )
        setPreviousTopicId(topicId)
        setPosts(filterPosts)
      })
      .finally(() => {
        setLoadingPosts(false)
        // const allPosts = posts
      })
  }

  const navigate = useNavigate()

  const [selectedTopic, setSelectedTopic] = useState(null)

  const handleTopicClick = (topicId: any) => {
    if (selectedTopic === topicId) {
      setSelectedTopic(null) // Deselect if the same topic is clicked again
    } else {
      setSelectedTopic(topicId) // Select the new topic
    }
    filterPostsBasedOnTopic(topicId)
    filterPostsBasedOnTopic(topicId)
  }

  function onClickFollowUser(userId: any) {
    followUsers(userId)
      .then((e) => {
        pageLoad(userId)
      })
      .catch((error) => {
        console.error('Error in onClickFollowUser:', error)
      })
  }

  const isFollowing = userDetails?.following
    ?.map((follow: any) => follow?.id)
    .includes(loginDetails?.id)

  const pageLoad = (userId: any) => {
    getUserDetails(userId)
      .then((data) => {
        setUserDetails(data)
      })
      .finally(() => {})
  }

  return (
    <>
      <div className={'mb-8'}>
        <div className="h-[38px]">
          <div className="fixed z-30 h-[38px] w-full justify-between border-b bg-white">
            <div className="mx-auto flex max-w-[390px] items-center">
              <img
                src={ArrowLeftSVG}
                className="h-6 w-6 cursor-pointer rounded-lg transition-all hover:bg-line"
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Tabs
                className="items-center py-2"
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                tabs={[
                  {
                    value: 'posts',
                    label: 'Posts',
                  },
                  {
                    value: 'articles',
                    label: 'Articles',
                  },
                ]}
              />

              <div className="h-6 w-6" />
            </div>
          </div>
        </div>

        <div className="mx-auto hidden max-w-[390px] mx-desktop:block">
          <DescriptionCardForScreens
            isPublic={userDetails?.public}
            isVerified={userDetails?.verified}
            name={userDetails?.name}
            location={userDetails?.userLocations?.address}
            description={userDetails?.description}
            role={userDetails?.roles?.name}
            profile={userDetails?.imageUrl}
            createdAt={userDetails?.createdAt}
            type={'user'}
            followingCount={userDetails?.following?.length}
            isFollowing={isFollowing}
            followingUser={onClickFollowUser}
            userId={userDetails?.id}
            cd={loadPosts}
          />
        </div>

        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <div className="fixed w-[30%] max-w-[600px]">
              <DescriptionCardForScreens
                isPublic={userDetails?.public}
                isVerified={userDetails?.verified}
                name={userDetails?.name}
                location={userDetails?.userLocations?.address}
                description={userDetails?.description}
                role={userDetails?.roles?.name}
                profile={userDetails?.imageUrl}
                createdAt={userDetails?.createdAt}
                type={'user'}
                followingUser={onClickFollowUser}
                followingCount={userDetails?.following?.length}
                isFollowing={isFollowing}
                userId={userDetails?.id}
                cd={loadPosts}
              />

              {/* <PostTypeWheel /> */}
              {currentTab === 'posts' && (
                <FilterWheelEntry
                  className="mt-10"
                  currentPostType={filterPostType}
                  setPostType={(type: string) => {
                    setFilterOrganization('all')
                    setFilterPostType(type)
                  }}
                  currentOrgType={filterOrganization}
                  setOrgType={(org: string) => {
                    setFilterPostType('all')
                    setFilterOrganization(org)
                  }}
                  setFilterOrder={setFilterOrder}
                  filterOrder={filterOrder}
                  setFilterTime={setFilterTime}
                  filterTime={filterTime}
                  postTypeWheelOpen={postTypeWheelOpen}
                  setPostTypeWheelOpen={setPostTypeWheelOpen}
                  orgWheelOpen={orgWheelOpen}
                  setOrgWheelOpen={setOrgWheelOpen}
                  disabledPostTypes={disabledItems.postTypes}
                  disabledOrgTypes={disabledItems.orgTypes}
                />
              )}
            </div>
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 mx-mobile:px-5"
            style={{
              flex: '0 0 40%',
            }}
          >
            {/* Filters */}
            {currentTab === 'posts' && (
              <div className="mb-4 mt-5 flex flex-wrap items-center gap-1 mobile:px-5">
                {filterPostType !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    Filtering {filterPostType}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterPostType('all')
                      }}
                    />
                  </div>
                )}

                {filterOrganization !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setOrgWheelOpen(true)
                    }}
                  >
                    Filtering {filterOrganization}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrganization('all')
                      }}
                    />
                  </div>
                )}

                {filterTime && filterTime !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      timeFilterOptions.find((x) => x.value === filterTime)
                        ?.label
                    }
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterTime(defaultFilterTime)
                      }}
                    />
                  </div>
                )}

                {filterOrder && filterOrder !== 'new-to-old' && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      orderFilterOptions.find((x) => x.value === filterOrder)
                        ?.label
                    }

                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrder(defaultFilterOrder)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {tab}
          </div>
          {/* Right div */}
          <div
            className="relative min-h-44 w-1/3 flex-shrink-0 overflow-hidden mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <div
              className={'mb-7 mt-7 flex flex-row items-center justify-center'}
            >
              <text
                className="font-weight-600 text-2xl font-normal text-[#9D9D9D]"
                style={{ fontFamily: 'Inter' }}
              >
                Filter topics
              </text>
              <img
                onClick={(event) => {
                  event.stopPropagation()
                  // if (navigateToProfile) {
                  //   navigateToProfile()
                  // }
                }}
                src={whiteLens}
                className="ml-1 h-[16px] w-[16px] object-cover"
              />
            </div>

            {topics?.map((topic: any) => (
              <UserScreenTopics
                key={topic?.id}
                topicKey={topic?.id}
                onClick={() => {
                  handleTopicClick(topic?.id)
                }}
                topicName={topic.title}
                selected={selectedTopic === topic.id}
              />
            ))}
          </div>
        </div>

        {currentTab === 'posts' && (
          <div
            className="h- fixed bottom-3 right-3 z-50 rounded-full bg-white desktop:hidden"
            style={{
              // '-webkit-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              // '-moz-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              boxShadow: 'rgb(255, 255, 255) 0px 0px 15px 8px',
            }}
          >
            <FilterWheelEntry
              currentPostType={filterPostType}
              setPostType={(type: string) => {
                setFilterOrganization('all')
                setFilterPostType(type)
              }}
              currentOrgType={filterOrganization}
              setOrgType={(org: string) => {
                setFilterPostType('all')
                setFilterOrganization(org)
              }}
              setFilterOrder={setFilterOrder}
              filterOrder={filterOrder}
              setFilterTime={setFilterTime}
              filterTime={filterTime}
              postTypeWheelOpen={postTypeWheelOpen}
              setPostTypeWheelOpen={setPostTypeWheelOpen}
              orgWheelOpen={orgWheelOpen}
              setOrgWheelOpen={setOrgWheelOpen}
              disabledPostTypes={disabledItems.postTypes}
              disabledOrgTypes={disabledItems.orgTypes}
            />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,
    permissions: state?.login?.user?.roles,

    topic: state.topics.topic,

    topicArticles: state.articles.topicArticles,
    topicsID: state.topics.topicsID,
    analyticsPosts: state.analytics.viewed_posts,
    loginDetails: state?.login?.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },
    getAllPostsForUser: (postsForUser: any) => {
      dispatch(getAllPostsForUser(postsForUser))
    },
    getUserDetails: (userId: any) => {
      dispatch(getUserDetails(userId))
    },
    getMe: () => {
      dispatch(getMe())
    },
    getTopicArticles: (topicId: string) => {
      dispatch(getTopicArticles(topicId))
    },
    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    deleteTopic: (topic: any) => {
      dispatch(deleteTopic(topic))
    },
    voteToggleTopic: (postId: any, topicId: any) => {
      dispatch(voteToggleTopic(postId, topicId))
    },
    alarmToggleTopic: (postId: any, topicId: any) => {
      dispatch(alarmToggleTopic(postId, topicId))
    },
    deleteArticlesForTopic: (artcileId: any, topicId: any) => {
      dispatch(deleteArticlesForTopic(artcileId, topicId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen)
