import { useEffect, useRef } from 'react'
import PostCard from './PostCard'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'
import { alarmToggle, upvoteToggle, followUsers } from './services'
import { cn } from '../../utils/cn'
import { parsePostObjToProps } from './helpers'
import { behaviorPlugin } from '@testing-library/user-event/dist/keyboard/types'

// const ContrastComp = ({ post, onClickVote, onClickAlarm, cb }: any) => {
//   // console.log(post.description, post)
//   return (
//     <PostCard
//       postId={post.id}
//       postType={post.postTypeId}
//       author={post.author?.name}
//       authorId={post.author?.id}
//       authorProfileUrl={post.author?.imageUrl}
//       authorRole={post.author?.roles?.name}
//       organizationProfileUrl={post.organization?.imageUrl}
//       organizationName={post.organization?.name}
//       organizationType={post.organization?.type}
//       votes={post.vote}
//       alarms={post.alarm}
//       content={post.description}
//       mediaUrl={post.mediaId}
//       articleId={post.articleId}
//       contrastCount={post.con?.length}
//       commentCount={post.com?.length}
//       createdAt={post.createdAt}
//       onClickVote={onClickVote}
//       onClickAlarm={onClickAlarm}
//       cb={cb}
//     />
//   )
// }

type Props = {
  userLocation?: any
  post: any
  pageLoad: () => void
  addPostsToRedux?: any
  blurMainFeedPosts?: boolean
  disableArticleButton?: boolean
  onNoAuthCallback?: () => void
  analyticsPosts?: any
} & React.HTMLAttributes<HTMLDivElement>

export default function PostWithContrast({
  post,
  pageLoad,
  className,
  userLocation,
  addPostsToRedux,
  blurMainFeedPosts,
  disableArticleButton,
  onNoAuthCallback,
  analyticsPosts,
}: Props) {
  const firstContrast = post?.con?.[0]
  const hasContrast = !!firstContrast

  function onClickVotePost() {
    upvoteToggle(post.id).then(() => {
      pageLoad()
    })
  }

  function onClickVoteContrast() {
    if (firstContrast) {
      upvoteToggle(firstContrast.id).then(() => {
        pageLoad()
      })
    }
  }

  function onClickAlarmPost() {
    alarmToggle(post.id).then(() => {
      pageLoad()
    })
  }

  function onClickAlarmContrast() {
    if (firstContrast) {
      alarmToggle(firstContrast.id).then(() => {
        pageLoad()
      })
    }
  }

  function onClickFollowUser(userId: any) {
    followUsers(userId).then(() => {
      pageLoad()
    })
  }

  return (
    <div className={cn('group relative flex w-full justify-center', className)}>
      {/* Left Padding */}
      <div className="h-5 flex-1 mx-desktop:hidden" />
      {/* Post */}
      <div className="w-[364px]">
        <div className="w-[364px] mx-mobile:w-full">
          <PostCard
            // className="mt-[30px]"
            analyticsPosts={analyticsPosts}
            postTypeForAnalytics={'home'}
            {...parsePostObjToProps(post)}
            onClickVote={onClickVotePost}
            onClickAlarm={onClickAlarmPost}
            cb={pageLoad}
            userLocation={userLocation}
            addPostsToRedux={addPostsToRedux}
            blurProfile={blurMainFeedPosts}
            disableArticleButton={disableArticleButton}
            onNoAuthCallback={onNoAuthCallback}
            onClickFollowUser={onClickFollowUser}
          />
        </div>

        {/* Mobile Contrast */}
        {hasContrast && post.showCon && (
          <div className="flex desktop:hidden">
            <div className="flex flex-col items-center pr-[10px]">
              <div className="h-[70px] w-[1px] bg-line" />

              <img src={ContrastSVG} className="h-4 w-4" />
            </div>
            <div className="mt-4 w-full max-w-[338px]">
              <PostCard
                analyticsPosts={analyticsPosts}
                postTypeForAnalytics={'home'}
                userLocation={userLocation}
                {...parsePostObjToProps(firstContrast)}
                onClickVote={onClickVoteContrast}
                onClickAlarm={onClickAlarmContrast}
                cb={pageLoad}
                addPostsToRedux={addPostsToRedux}
                blurProfile
                disableArticleButton={disableArticleButton}
                onNoAuthCallback={onNoAuthCallback}
                onClickFollowUser={onClickFollowUser}
              />
            </div>
          </div>
        )}
      </div>

      {/* Desktop Contrast */}
      {hasContrast && post.showCon && (
        <div className="absolute -right-[412px] z-20 w-full max-w-[364px] mx-desktop:hidden">
          <PostCard
            analyticsPosts={analyticsPosts}
            postTypeForAnalytics={'home'}
            userLocation={userLocation}
            {...parsePostObjToProps(firstContrast)}
            onClickVote={onClickVoteContrast}
            onClickAlarm={onClickAlarmContrast}
            cb={pageLoad}
            addPostsToRedux={addPostsToRedux}
            blurProfile
            disableArticleButton={disableArticleButton}
            onNoAuthCallback={onNoAuthCallback}
            onClickFollowUser={onClickFollowUser}
          />
        </div>
      )}
      {/* Right Padding */}
      <div className="h-auto flex-1 mx-desktop:hidden">
        {hasContrast && post.showCon && (
          <div className="-mr-[10px] mt-0 h-full overflow-auto bg-background">
            <div className="mt-14 flex items-center">
              <div className="w-5" />
              <div className="flex-1 border-b-[1px] border-line" />
              {/* <div className="h-5 w-5 rounded-full bg-line" /> */}
              <img src={ContrastSVG} className="-mt-[1px] h-4 w-4" />
            </div>
          </div>
        )}
      </div>
      {/* Line breaker */}
      {/* <div className="h-full flex-1" /> */}
    </div>
  )
}
